<script lang="ts" setup>
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'
import type { RouteLocationRaw } from 'vue-router'
import type { MenuLink } from '~~/app/types/navigation'

interface Props {
    link: MenuLink;
}

const props = defineProps<Props>()

const linkElement = ref(null)
const linkDropdown = ref(null)

const runtimeConfig = useRuntimeConfig()

const isLinkHovered = useElementHover(linkElement, {
  delayEnter: 250,
  delayLeave: 250,
})
const isDropdownHovered = useElementHover(linkDropdown, {
  delayLeave: 200,
})

const { floatingStyles } = useFloating(linkElement, linkDropdown, {
  placement: 'bottom',
  whileElementsMounted: autoUpdate,
  middleware: [offset(10), flip(), shift()],
})

const hasChildren = computed(() => props.link.children.length > 0)
const isDropdownActive = computed(
  () => isLinkHovered.value || isDropdownHovered.value
)

function shouldOpenNewTab(url: RouteLocationRaw): boolean {
  if (typeof url !== 'string') {
    return false
  }

  return (
    url.startsWith('http') && !url.startsWith(runtimeConfig.public.baseUrl)
  )
}
</script>

<template>
    <LinkText v-if="!hasChildren" :to="props.link.url">
        {{ props.link.text }}
    </LinkText>

    <div v-else>
        <LinkText
            ref="linkElement"
            :to="props.link.url"
            :target="shouldOpenNewTab(props.link.url) ? '_blank' : undefined"
            class="group h-full block"
        >
            {{ props.link.text }}

            <IconChevron
                :size="0.5"
                class="inline ml-1.5 rotate-90 group-hover:-rotate-90 transition-transform"
            />
        </LinkText>

        <div
            v-if="isDropdownActive"
            ref="linkDropdown"
            class="p-5 flex flex-col gap-2 bg-white border border-uk-orange rounded-xl z-50"
            :style="floatingStyles"
        >
            <LinkText
                v-for="child in props.link.children"
                :key="child.text"
                :to="child.url"
                :target="shouldOpenNewTab(child.url) ? '_blank' : undefined"
                class="group h-full block"
            >
                {{ child.text }}
            </LinkText>
        </div>
    </div>
</template>

<style scoped></style>

<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 1,
})
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 58 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M52.0386 3.97643L44.1922 39.4678C43.6002 41.9727 42.0565 42.5962 39.8627 41.4161L27.9074 32.9663L22.1386 38.2878C21.5002 38.9001 20.9663 39.4122 19.7359 39.4122L20.5949 27.7339L42.7529 8.52975C43.7163 7.70593 42.544 7.24948 41.2556 8.07331L13.8627 24.6167L2.06987 21.0764C-0.495311 20.3083 -0.541739 18.6161 2.6038 17.436L48.7306 0.391668C50.8663 -0.376496 52.7351 0.848113 52.0386 3.97643Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>

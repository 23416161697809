<script lang="ts" setup>
import {formatPrice} from "../../utils/formatter"

const cart = useCartStore()

const timeToLive = 5000

const isActive = ref(false)
const itemsCount = ref(cart.state.items.length)
const lastOpened = ref(0)

function open() {
  isActive.value = true
  itemsCount.value = cart.state.items.length
  lastOpened.value = Date.now()

  useDebounceFn(() => {
    closeWithDelay()
  }, timeToLive)()
}

function close() {
  isActive.value = false
}

function closeWithDelay() {
  if (Date.now() - lastOpened.value < timeToLive) {
    return
  }

  close()
}

onUnmounted(() => {
  close()
})

watch(
  () => cart.state.items.length,
  (newValue) => {
    if (newValue < itemsCount.value) {
      return
    }

    open()
  }
)
</script>

<template>
    <Teleport to="body">
        <Transition name="fade-in">
            <section
                v-if="isActive"
                v-bind="$attrs"
                class="fixed top-4 right-4 h-fit max-h-[95vh] w-fit max-w-[92%] m-auto z-[999] bg-white rounded-xl drop-shadow border border-uk-blue overflow-hidden"
            >
                <main
                    class="w-full lg:w-[520px] h-96 relative max-h-[85vh] overflow-auto overscroll-contain scrollbar-none"
                >
                    <div
                        class="flex py-6 px-6 lg:px-12 bg-uk-creamy justify-between items-center"
                    >
                        <TextSubtitle bold>Добавлено в корзину!</TextSubtitle>

                        <IconClose
                            class="cursor-pointer text-uk-gray hover:text-gray-500"
                            @click="close"
                        />
                    </div>

                    <div
                        class="flex flex-col divide-y px-12 py-6 gap-4 h-60 pb-8 overflow-scroll overscroll-contain scrollbar-none"
                    >
                        <div
                            v-for="item in cart.state.items"
                            :key="item.id"
                            class="flex gap-4 first-of-type:pt-0 pt-4"
                        >
                            <NuxtImg
                                v-if="item.image"
                                :src="item.image"
                                :alt="item.name"
                                class="shrink-0 rounded-xl"
                                width="120"
                            />
                            <CartImagePlaceholder v-else />

                            <div class="flex flex-col">
                                <div class="flex flex-col">
                                    <TextBody bold>
                                        {{ item.name }}

                                        <template v-if="item.option">
                                            ({{ item.option.name }})
                                        </template>
                                    </TextBody>
                                    <TextBody>
                                        Количество: {{ item.quantity }}
                                    </TextBody>
                                </div>

                                <div class="flex gap-4">
                                    <TextBody bold>
                                        {{ formatPrice(item.price) }}
                                    </TextBody>
                                    <TextBody
                                        v-if="item.oldPrice"
                                        class="text-uk-gray line-through"
                                    >
                                        {{ formatPrice(item.oldPrice) }}
                                    </TextBody>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="absolute w-full bg-white z-10 bottom-0 py-3 flex justify-center"
                        style="box-shadow: 0 -5px 15px -10px rgba(0, 0, 0, 0.2)"
                    >
                        <LinkColored
                            to="/cart"
                            variant="primary-border"
                            class="w-fit"
                        >
                            Посмотреть весь заказ
                        </LinkColored>
                    </div>
                </main>
            </section>
        </Transition>
    </Teleport>
</template>

<style scoped></style>

<script setup lang="ts">
const env = useEnvironment()
const runtimeConfig = useRuntimeConfig()

useHead({
  link: () => [
    // API
    {
      rel: 'preconnect',
      href: runtimeConfig.public.sanctum.baseUrl,
      crossorigin: '',
    },
    // Google Tag Manager
    {
      rel: 'preconnect',
      href: 'https://www.googletagmanager.com',
      crossorigin: '',
    },
  ],
})

if (env.isProduction) {
  useHead({
    script: () => [
      {
        src: '/scripts/yandex.metric.js',
        async: false,
        defer: true,
      },
    ],
    link: () => [
      // Yandex Metric
      {
        rel: 'preconnect',
        href: 'https://mc.yandex.ru',
        crossorigin: '',
      },
      {
        rel: 'preconnect',
        href: 'https://mc.yandex.com',
        crossorigin: '',
      },
    ],
  })
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
</template>

<script lang="ts" setup>
interface Props {
    bold?: boolean;
}

const props = defineProps<Props>()
</script>

<template>
    <p
        :class="{ 'font-semibold': props.bold }"
        class="text-base lg:text-xl xl:text-2xl"
    >
        <slot />
    </p>
</template>

<style scoped></style>

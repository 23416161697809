import type { News } from '../models/Posts'
import type {
  PaginatedCollection,
  Resource,
  ResourceCollection,
} from '../types/responses'
import ApiService from './base/ApiService'

export default class NewsService extends ApiService {
  async index(page: number): Promise<PaginatedCollection<News>> {
    const endpoint = '/api/news'
    const response = await this.fetch<PaginatedCollection<News>>(endpoint, {
      query: { page },
    })

    return response
  }

  async get(slug: string): Promise<Resource<News>> {
    const endpoint = `/api/news/${slug}`
    const response = await this.fetch<Resource<News>>(endpoint)

    return response
  }

  async getLatest(): Promise<ResourceCollection<News>> {
    const endpoint = '/api/news/latest'
    const response = await this.fetch<ResourceCollection<News>>(endpoint)

    return response
  }

  async getSimilar(slug: string): Promise<ResourceCollection<News>> {
    const endpoint = `/api/news/${slug}/similar`
    const response = await this.fetch<ResourceCollection<News>>(endpoint)

    return response
  }
}

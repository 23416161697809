import type { Article } from '../models/Posts'
import type {
  PaginatedCollection,
  Resource,
  ResourceCollection,
} from '../types/responses'
import ApiService from './base/ApiService'

export default class ArticleService extends ApiService {
  async index(page: number): Promise<PaginatedCollection<Article>> {
    const endpoint = '/api/articles'
    const response = await this.fetch<PaginatedCollection<Article>>(
      endpoint,
      {
        query: { page },
      }
    )

    return response
  }

  async get(slug: string): Promise<Resource<Article>> {
    const endpoint = `/api/articles/${slug}`
    const response = await this.fetch<Resource<Article>>(endpoint)

    return response
  }

  async getLatest(): Promise<ResourceCollection<Article>> {
    const endpoint = '/api/articles/latest'
    const response =
            await this.fetch<ResourceCollection<Article>>(endpoint)

    return response
  }

  async getSimilar(slug: string): Promise<ResourceCollection<Article>> {
    const endpoint = `/api/articles/${slug}/similar`
    const response =
            await this.fetch<ResourceCollection<Article>>(endpoint)

    return response
  }
}

<script lang="ts" setup>
interface Props {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
    side?: 'left' | 'right';
    background?: 'white' | 'gray';
    disposable?: boolean;
    showCloseIcon?: boolean;
}

interface Events {
    (e: 'open'): void;
    (e: 'close'): void;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
  side: 'right',
  background: 'white',
  disposable: true,
  showCloseIcon: true,
})

const emit = defineEmits<Events>()

const isActive = ref(false)
const router = useRouter()

const overlayClasses = computed(() => {
  return {
    'right-0': props.side === 'right',
    'left-0': props.side === 'left',
    'pt-14': props.showCloseIcon,
    'w-64': props.size === 'xs',
    'w-80': props.size === 'sm',
    'w-96': props.size === 'md',
    'w-1/3': props.size === 'lg',
    'w-1/2': props.size === 'xl',
    'w-2/3': props.size === '2xl',
    'w-full': props.size === 'full',
    'bg-white': props.background === 'white',
    'bg-uk-white': props.background === 'gray',
  }
})

const closeIconClasses = computed(() => {
  return {
    'left-3 right-auto': props.side === 'right',
    'left-auto right-3': props.side === 'left',
  }
})

function open() {
  isActive.value = true

  const scrollY = `-${window.scrollY}px`

  document.body.style.overflow = 'hidden'
  document.body.style.top = scrollY

  emit('open')
}

function close() {
  isActive.value = false

  const scrollY = document.body.style.top

  document.body.style.overflow = ''
  document.body.style.top = ''

  window.scrollTo(0, parseInt(scrollY || '0') * -1)

  emit('close')
}

function toggle() {
  if (isActive.value) {
    close()
  } else {
    open()
  }
}

function dispose() {
  if (!props.disposable) {
    return
  }

  close()
}

defineExpose({
  open,
  close,
  toggle,
})

defineOptions({
  inheritAttrs: false,
})

watch(router.currentRoute, () => {
  dispose()
})

onUnmounted(() => {
  dispose()
})
</script>

<template>
    <Teleport to="body">
        <Transition name="fade-in">
            <section
                v-if="isActive"
                class="w-full h-full fixed inset-0 backdrop-brightness-75 select-none z-40 overscroll-contain"
                @click.self="dispose"
            />
        </Transition>

        <Transition
            :name="`slide-${props.side}`"
            :class="overlayClasses"
            class="fixed h-full top-0 z-50 overflow-auto overscroll-contain p-7"
            v-bind="$attrs"
        >
            <aside v-if="isActive">
                <IconClose
                    v-if="props.showCloseIcon"
                    class="absolute inset-3 cursor-pointer text-uk-gray hover:text-gray-500"
                    :class="closeIconClasses"
                    @click="close"
                />

                <slot :close="close" />
            </aside>
        </Transition>
    </Teleport>
</template>

<style scoped lang="scss">
.slide-right-enter-active {
    transition: all 0.3s ease-out;
}

.slide-right-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-right-enter-from,
.slide-right-leave-to {
    transform: translateX(100%);
}

.slide-left-enter-active {
    transition: all 0.3s ease-out;
}

.slide-left-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-left-enter-from,
.slide-left-leave-to {
    transform: translateX(-100%);
}

.fade-in-enter-active,
.fade-in-leave-active {
    transition: all 0.1s ease-in-out;
}

.fade-in-enter-from,
.fade-in-leave-to {
    opacity: 0;
}
</style>

<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 1,
})
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 17 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.29612 0.935967C1.07595 1.15569 0.901271 1.41667 0.78209 1.70398C0.662909 1.9913 0.601562 2.29929 0.601562 2.61034C0.601562 2.92139 0.662909 3.22939 0.78209 3.5167C0.901271 3.80401 1.07595 4.065 1.29612 4.28472L10.5111 13.4997L1.29612 22.7147C0.852046 23.1588 0.602568 23.7611 0.602568 24.3891C0.602568 25.0171 0.852046 25.6194 1.29612 26.0635C1.74019 26.5075 2.34248 26.757 2.97049 26.757C3.5985 26.757 4.2008 26.5075 4.64487 26.0635L15.5461 15.1622C15.7663 14.9425 15.941 14.6815 16.0601 14.3942C16.1793 14.1069 16.2407 13.7989 16.2407 13.4878C16.2407 13.1768 16.1793 12.8688 16.0601 12.5815C15.941 12.2942 15.7663 12.0332 15.5461 11.8135L4.64487 0.912216C3.74237 0.00971617 2.22237 0.00971673 1.29612 0.935967Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>

<script setup lang="ts">
import TelegramBotButton from '~~/app/components/TelegramBotButton.vue'

interface ErrorProps {
  error?: {
    url: string;
    statusCode: number;
    statusMessage: string;
    message: string;
    description: string;
    data?: any;
  };
}

const props = defineProps<ErrorProps>()
const appConfig = useAppConfig()
const route = useRoute()
const runtimeConfig = useRuntimeConfig()
const env = useEnvironment()

const configStore = useConfigStore()
const contextStore = useContextStore()

useSeoMeta({
  ogSiteName: appConfig.title,
  ogUrl: () => `${runtimeConfig.public.baseUrl}${route.path}`,
  ogImage: () => `${runtimeConfig.public.baseUrl}/images/logo.png`,
})

useHead({
  title: `Неприятная ситуация | ${appConfig.title}`,
})

const tasks = [configStore.fetch(), contextStore.fetch()]

try {
  await callOnce(async () => {
    await Promise.all(tasks)
  })
} catch (error) {
  if (env.isDevelopment) {

    console.error(error)
  }
}

const humanizedError = computed(() => {
  if (!props.error) {
    return 'Неизвестная ошибка'
  }

  switch (props.error.statusCode) {
    case 404:
      return 'Страница не найдена'
    case 429:
      return 'От вас поступило слишком много запросов, попробуйте немного позже'
    case 500:
      return 'Что-то пошло не так, мы уже работаем над этим'
    default:
      return 'Неизвестная ошибка'
  }
})
</script>

<template>
  <NavigationPanel/>

  <div class="w-full h-[64vh] lg:h-auto relative text-white">
    <img
      src="~/assets/images/error-bg.png?format=avif"
      alt="Ошибка"
      class="w-full h-full object-cover"
      preload
    >

    <div class="absolute w-full inset-y-2/3">
      <div class="flex flex-col w-fit items-center gap-12 mx-auto">
        <TextHeading :level="1">
          {{ humanizedError }}
        </TextHeading>

        <LinkColored to="/courses"> Вернуться к курсам</LinkColored>
      </div>
    </div>
  </div>

  <FooterPanel/>
  <Cookies/>
  <ShopCartPopup/>
  <TelegramBotButton/>
  <WhatsappButton/>
</template>

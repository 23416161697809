<script lang="ts" setup>
const rb = useRouteBuilder()
const { courseService } = useApi()

const { data } = await useAsyncData(() => courseService.getCategories())

const categories = computed(() => data.value?.data ?? [])
</script>

<template>
    <div class="flex flex-col gap-y-5">
        <TextBody bold>Направления</TextBody>

        <nav
            class="grid grid-cols-2 gap-x-2 xl:gap-x-16 gap-y-1 xl:gap-y-2 items-start"
        >
            <LinkText
                v-for="category in categories"
                :key="category.slug"
                :to="rb.toCourseCategory(category)"
                size="sm"
            >
                {{ category.name }}
            </LinkText>
        </nav>
    </div>
</template>

<style scoped></style>

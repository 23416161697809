<script lang="ts" setup>
const runtimeConfig = useRuntimeConfig()

const isActive = ref(false)
const isAccepted = useLocalStorage<boolean>('cookies.accepted', false)

function showNotification() {
  if (isAccepted.value === true) {
    return
  }

  isActive.value = !isActive.value
}

function closeNotification() {
  isActive.value = false
}

function onAccept() {
  isAccepted.value = true
  closeNotification()
}

onMounted(() => {
  useDebounceFn(() => {
    showNotification()
  }, 2000)()
})
</script>

<template>
    <Transition name="popup" appear>
        <section v-if="isActive" class="fixed inset-x-0 bottom-10 z-[999999]">
            <div
                class="bg-white mx-auto max-w-xl flex flex-col gap-6 drop-shadow px-10 py-8 rounded-xl"
            >
                <TextBody>
                    Используя сайт {{ runtimeConfig.public.baseUrl }}, вы
                    соглашаетесь с использованием файлов cookie
                </TextBody>

                <div class="flex gap-6">
                    <ButtonColored variant="primary-border" @click="onAccept">
                        ОК
                    </ButtonColored>
                    <LinkColored to="/policy" variant="primary-inverse-border">
                        Узнать подробнее
                    </LinkColored>
                </div>
            </div>
        </section>
    </Transition>
</template>

<style scoped>
.popup-enter-active,
.popup-leave-active {
    transition: all 0.3s ease-out;
}

.popup-enter-from,
.popup-leave-to {
    opacity: 0;
    transform: translateY(200px);
}
</style>

<script lang="ts" setup>
const { remoteConfig } = useConfigStore()
</script>

<template>
    <LinkColored
        :to="remoteConfig.EduPortalLink"
        target="_blank"
        size="sm"
        class="min-w-fit"
        variant="primary-inverse"
    >
        Учебный портал
    </LinkColored>
</template>

<style scoped></style>

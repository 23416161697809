import { useCallTracking } from '~~/app/composables/useCallTracking'

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) {
    return
  }

  if (import.meta.env.DEV) {
    return
  }

  useCallTracking()
})

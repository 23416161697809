<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 1,
})
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 18 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.2094 15.598C16.8072 14.7789 15.6722 14.089 14.1632 15.2525C12.1081 16.8902 8.832 16.8895 8.832 16.8895C8.832 16.8895 5.55587 16.8888 3.50145 15.2503C1.99296 14.086 0.85766 14.7755 0.455153 15.5945C-0.263603 17.0312 0.54091 17.7355 2.36552 18.8997C3.93153 19.9059 6.07243 20.2799 7.45182 20.4239L6.30205 21.5732C4.66364 23.1966 3.08272 24.7624 2.00481 25.8542C1.35807 26.5151 1.35784 27.564 2.00431 28.182L2.20543 28.412C2.8519 29.0587 3.9152 29.0589 4.56194 28.4125L8.83045 24.1314C10.4825 25.7555 12.0484 27.322 13.1402 28.4143C13.7867 29.061 14.8356 29.0613 15.4967 28.4148L15.6836 28.1849C16.3447 27.5672 16.3449 26.5183 15.6841 25.8572L11.403 21.5743L10.2681 20.4102C11.6332 20.2811 13.7455 19.8936 15.2976 18.9025C17.1227 17.739 17.9275 17.0351 17.2094 15.598ZM8.83478 3.85678C10.8177 3.85721 12.4267 5.46688 12.4263 7.43543C12.4258 9.41835 10.8162 11.013 8.83325 11.0125C6.85033 11.0121 5.24135 9.41682 5.24177 7.4339C5.24219 5.46534 6.85186 3.85636 8.83478 3.85678ZM8.83246 14.7054C12.8558 14.7062 16.1039 11.4595 16.1047 7.43622C16.1051 5.5079 15.3395 3.65841 13.9763 2.29459C12.613 0.930781 10.7639 0.164367 8.83557 0.163955C6.90726 0.163543 5.05776 0.929167 3.69395 2.2924C2.33013 3.65563 1.56372 5.5048 1.56331 7.43311C1.56245 11.4564 4.80915 14.7045 8.83246 14.7054Z"
            fill="currentColor"
        />
    </svg>
</template>

<style scoped></style>

<script lang="ts" setup>
import type { AccordionContext } from '~~/layers/uikit/types/accordion'

const componentId = useId().replace('-', '_')
const context = inject<AccordionContext>('AccordionContext')!

const isActive = computed(() => {
  if (context.selected.value === undefined) {
    return false
  }

  if (context.selected.value instanceof Set) {
    return context.selected.value.has(componentId)
  }

  return context.selected.value === componentId
})

const iconClasses = computed(() => {
  return {
    '-rotate-90': isActive.value,
    'rotate-90': !isActive.value,
  }
})

function toggle() {
  context.toggleTab(componentId)
}

onMounted(() => {
  context.registerTab(componentId)
})
</script>

<template>
    <span
        :class="{ 'text-uk-orange': isActive }"
        class="flex items-center gap-2 transition-colors cursor-pointer hover:text-uk-orange"
        v-bind="$attrs"
        @click="toggle"
    >
        <div>
            <slot name="header" />
        </div>

        <IconChevron
            :size="0.7"
            :class="iconClasses"
            class="mt-1 transition-transform shrink-0"
        />
    </span>

    <span v-if="isActive">
        <slot name="default" />
    </span>
</template>

<style scoped></style>

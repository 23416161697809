<script lang="ts" setup>
import type { AccordionContext } from '../../types/accordion'

interface Props {
    multiple?: boolean;
    selectedIndices?: number | number[];
}

const props = withDefaults(defineProps<Props>(), {
  multiple: true,
  selectedIndices: undefined,
})

const itemIdentifiers = ref<string[]>([])

const selectedItemIdentifiers = ref<string | Set<string> | undefined>(
  props.multiple ? new Set() : undefined
)

function toggleTab(tabId: string) {
  if (selectedItemIdentifiers.value instanceof Set) {
    if (selectedItemIdentifiers.value.has(tabId)) {
      selectedItemIdentifiers.value.delete(tabId)
      return
    }

    selectedItemIdentifiers.value.add(tabId)

    return
  }

  if (selectedItemIdentifiers.value === tabId) {
    selectedItemIdentifiers.value = undefined
    return
  }

  selectedItemIdentifiers.value = tabId
}

function registerTab(tabId: string) {
  itemIdentifiers.value.push(tabId)
}

provide<AccordionContext>('AccordionContext', {
  selected: selectedItemIdentifiers,
  toggleTab,
  registerTab,
})

onMounted(() => {
  if (!props.selectedIndices) {
    return
  }

  const itemIndicesToSelect = Array.isArray(props.selectedIndices)
    ? props.selectedIndices
    : [props.selectedIndices]

  selectedItemIdentifiers.value = new Set(
    itemIndicesToSelect.map((index) => itemIdentifiers.value[index]!)
  )
})
</script>

<template>
    <section>
        <slot />
    </section>
</template>

<style scoped></style>

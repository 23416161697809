


import { useRandomId } from '~~/app/composables/useRandomId'

export const useCallTracking = () => {
  const previousTag = document.scripts.namedItem('callTracking')

  if (previousTag) {
    previousTag.remove()
  }

  (function (w: any, c: any) {
    (w[c] = w[c] || []).push(function () {
      // @ts-expect-error JavaScript w/o types
      new zTracker({
        id: 'd8a78f4aa43090fcbd381f29f3f28a3c5313',
        metrics: {
          metrika: '45309849',
          ga: 'UA-178418045-1',
        },
        domain: 'flagmantc.ru',
      })
    })
  })(window, 'zTrackerCallbacks')

  useHead({
    script: () => [
      {
        id: 'callTracking',
        src: 'https://my.novofon.com/js/ct_phone.min.js',
        async: false,
        defer: true,
        key: useRandomId(13),
      },
    ],
    link: () => [
      {
        rel: 'preconnect',
        href: 'https://my.novofon.com',
        crossorigin: '',
      },
    ],
  })
}

import type { DocumentType } from '../enum/DocumentType'
import type { Documents } from '../models/Documents'
import type { ResourceCollection } from '../types/responses'
import ApiService from './base/ApiService'

export default class DocumentsService extends ApiService {
  /**
     * @deprecated Use `getByType` instead.
     */
  async getAll(): Promise<ResourceCollection<Documents>> {
    const endpoint = '/api/documents'
    const response =
            await this.fetch<ResourceCollection<Documents>>(endpoint)

    return response
  }

  async getByType(
    type: DocumentType
  ): Promise<ResourceCollection<Documents>> {
    const endpoint = '/api/documents'
    const response = await this.fetch<ResourceCollection<Documents>>(
      endpoint,
      {
        query: { type },
      }
    )

    return response
  }
}

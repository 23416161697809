import type {Order, OrderHistoryItem} from '../models/Order'
import ApiService from './base/ApiService'
import type {Cart} from '~~/app/types/cart'
import type {ResourceCollection} from "~~/layers/api/types/responses"

export default class OrderService extends ApiService {
  async create(cart: Cart): Promise<Order> {
    const endpoint = '/api/orders'

    return await this.fetch<Order>(endpoint, {
      method: 'post',
      body: cart,
    })
  }

  async history(): Promise<ResourceCollection<OrderHistoryItem>> {
    const endpoint = '/api/orders/history'

    return await this.fetch<ResourceCollection<OrderHistoryItem>>(endpoint)
  }
}

import ApiService from './base/ApiService'

export default class CustomerRequestService extends ApiService {
  async createNewRequest(payload: Record<string, any>): Promise<void> {
    const endpoint = '/api/customer-requests'
    const response = await this.fetch(endpoint, {
      method: 'post',
      body: payload,
    })

    return response
  }
}

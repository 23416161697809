const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const useRandomId = (length: number) => {
  let result = ''
  let counter = 0

  while (counter < length) {
    result += characters.charAt(
      Math.floor(Math.random() * characters.length)
    )

    counter += 1
  }

  return result
}

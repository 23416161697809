<script lang="ts" setup>
import { VueSpinner } from 'vue3-spinners'

interface Props {
    active: boolean;
}

const props = defineProps<Props>()
</script>

<template>
    <VueSpinner v-if="props.active" class="mx-auto text-uk-orange" :size="30" />
</template>

<style scoped></style>

<script lang="ts" setup>
import Overlay from '~~/layers/uikit/components/Overlay.vue'

const sidebar = ref<InstanceType<typeof Overlay> | null>(null)

function toggleSidebar() {
  if (!sidebar.value) {
    return
  }

  sidebar.value?.toggle()
}

function reloadCallTracking() {
  useCallTracking()
}
</script>

<template>
    <IconHamburger class="cursor-pointer" @click="toggleSidebar" />

    <Overlay
        ref="sidebar"
        side="left"
        size="full"
        background="gray"
        :show-close-icon="false"
        @open="reloadCallTracking"
    >
        <div class="flex flex-col gap-5">
            <div class="flex items-center justify-between">
                <Logo />
                <IconClose
                    class="text-uk-blue cursor-pointer"
                    @click="toggleSidebar"
                />
            </div>

            <NavigationSearch class="w-full" />

            <NavigationMobileLinks />

            <NavigationAddress />
            <NavigationPhone />

            <div class="flex gap-4">
                <NavigationCoursesLink />
                <NavigationPortalLink />
            </div>

            <NavigationSocial />
        </div>
    </Overlay>
</template>

<style scoped></style>

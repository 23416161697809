import type { CartState } from '../models/Cart'
import ApiService from './base/ApiService'

export default class CartService extends ApiService {
  async syncState(
    productIds: Array<number>,
    promocode: string | undefined
  ): Promise<CartState> {
    const endpoint = '/api/cart/sync'
    const response = await this.fetch<CartState>(endpoint, {
      method: 'POST',
      body: {
        products: productIds,
        promocode,
      },
    })

    return response
  }
}

import {DateTime} from 'luxon'
import {JobPostType} from '~~/layers/api/enum/JobPostType'
import {SearchResultType} from '~~/layers/api/enum/SearchResultType'
import type {Course, CourseEntry} from '~~/layers/api/models/Courses'
import {PaymentMethod} from '~~/app/enum/payment'
import {OrderStatus} from "~~/layers/api/enum/OrderStatus"

export function formatJobType(type: JobPostType) {
  switch (type) {
    case JobPostType.FullTime:
      return 'Полная занятость'
    case JobPostType.PartTime:
      return 'Частичная занятость'
    case JobPostType.Mixed:
      return 'Полная занятость / Частичная занятость'
    default:
      return 'Не указано'
  }
}

export function formatTimestampAsDate(timestamp: string) {
  const dateFormat = 'dd.MM.yyyy'
  const dateTime = DateTime.fromISO(timestamp)

  return dateTime.toFormat(dateFormat)
}

export function formatPaymentMethod(method: PaymentMethod) {
  const paymentMethods = {
    [PaymentMethod.Installments3]: `В рассрочку на 3 ${formatPlural(3, 'месяц', 'месяца', 'месяцев')}`,
    [PaymentMethod.Installments4]: `В рассрочку на 4 ${formatPlural(4, 'месяц', 'месяца', 'месяцев')}`,
    [PaymentMethod.Installments6]: `В рассрочку на 6 ${formatPlural(6, 'месяц', 'месяца', 'месяцев')}`,
    [PaymentMethod.Installments10]: `В рассрочку на 10 ${formatPlural(10, 'месяц', 'месяца', 'месяцев')}`,
    [PaymentMethod.Installments12]: `В рассрочку на 12 ${formatPlural(12, 'месяц', 'месяца', 'месяцев')}`,
    [PaymentMethod.Dolyame]: 'Оплата Долями',
    [PaymentMethod.Card]: 'Картой Visa, MasterCard, МИР',
  }

  return paymentMethods[method]
}

export function formatPrice(price: number) {
  const roundedPrice = Math.round(price)

  return roundedPrice.toLocaleString('ru-RU') + ' ₽'
}

export function formatPlural(
  n: number,
  one: string,
  few: string,
  many: string
) {
  n = Math.abs(n)

  // For numbers ending in 11, 12, 13, 14
  if (n % 100 >= 11 && n % 100 <= 14) {
    return many
  }

  // For numbers ending in 1
  if (n % 10 === 1) {
    return one
  }

  // For numbers ending in 2, 3, 4
  if (n % 10 >= 2 && n % 10 <= 4) {
    return few
  }

  // For all other numbers
  return many
}

export function formatDuration(course: Course | CourseEntry): [number, string] {
  if (course.duration_months) {
    return [
      course.duration_months,
      formatPlural(course.duration_months, 'месяц', 'месяца', 'месяцев'),
    ]
  }

  if (course.duration_days) {
    return [
      course.duration_days,
      formatPlural(course.duration_days, 'день', 'дня', 'дней'),
    ]
  }

  if (course.duration_hours) {
    return [
      course.duration_hours,
      formatPlural(course.duration_hours, 'час', 'часа', 'часов'),
    ]
  }

  return [0, '']
}

export function formatCourseCohortDate(course: Course) {
  if (course.cohorts.length === 0) {
    return 'Расписание готовится'
  }

  const cohort = course.cohorts[0]!

  const dateFormat = 'dd MMMM yyyy'
  const dateTime = DateTime.fromISO(cohort.start_date)

  return dateTime.toFormat(dateFormat)
}

export function formatSearchResultType(type: SearchResultType) {
  switch (type) {
    case SearchResultType.Course:
      return 'Курс'
    case SearchResultType.Certificate:
      return 'Сертификат'
    case SearchResultType.Document:
      return 'Документ'
    default:
      return ''
  }
}

export function formatOrderStatus(status: OrderStatus) {
  switch (status) {
    case OrderStatus.Processing:
      return 'Обрабатывается'
    case OrderStatus.Pending:
      return 'Ожидает оплаты'
    case OrderStatus.Completed:
      return 'Оплачен'
    case OrderStatus.Failed:
      return 'Ошибка оплаты'
    default:
      return ''
  }
}

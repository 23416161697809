<script lang="ts" setup>
</script>

<template>
    <div class="py-4 hidden lg:block">
        <Container class="flex items-center justify-between">
            <Logo />
            <NavigationSearch class="w-[130px] xl:w-[185px] 2xl:w-auto" />
            <NavigationBarLinks />

          <div class="flex gap-4">
            <NavigationBarCart />
            <NavigationBarProfile />
          </div>
        </Container>
    </div>
</template>

<style scoped></style>

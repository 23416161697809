import type { Promocode } from '../models/Promocode'
import type { Resource } from '../types/responses'
import ApiService from './base/ApiService'

export default class PromocodeService extends ApiService {
  async findByCode(code: string): Promise<Resource<Promocode>> {
    const endpoint = `/api/promocodes/${code}`
    const response = await this.fetch<Resource<Promocode>>(endpoint)

    return response
  }
}

<script lang="ts" setup>
const { remoteConfig } = useConfigStore()
</script>

<template>
    <TextMenu class="max-w-[13rem]">
        {{ remoteConfig.CompanyAddress }}
    </TextMenu>
</template>

<style scoped></style>

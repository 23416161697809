import ApiService from './base/ApiService'

export default class UserService extends ApiService {
  async update(fields: Record<string, any>): Promise<void> {
    const endpoint = '/api/user'

    return await this.fetch(endpoint, {
      method: 'POST',
      body: fields,
    })
  }

  async changePassword(password: string, password_confirmation: string): Promise<void> {
    const endpoint = '/api/user/password'

    return await this.fetch(endpoint, {
      method: 'POST',
      body: { password, password_confirmation },
    })
  }
}

<script lang="ts" setup>
interface Props {
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 1,
})
</script>

<template>
    <svg
        :width="21 * props.size"
        :height="21 * props.size"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_564_10607)">
            <path
                d="M24.7342 10.1575L12.8838 1.48093C12.6553 1.31365 12.3448 1.31365 12.1164 1.48093L0.265897 10.1575C-0.0235557 10.3695 -0.0864464 10.776 0.125517 11.0654C0.337479 11.3549 0.743974 11.4177 1.03338 11.2058L12.5 2.81008L23.9667 11.2057C24.0823 11.2905 24.2167 11.3312 24.3499 11.3312C24.5499 11.3312 24.7472 11.2392 24.8745 11.0654C25.0865 10.776 25.0236 10.3695 24.7342 10.1575Z"
                fill="currentColor"
            />
            <path
                d="M21.5954 11.3555C21.2367 11.3555 20.9459 11.6463 20.9459 12.005V22.3443H15.7495V16.7005C15.7495 14.9095 14.2923 13.4524 12.5014 13.4524C10.7105 13.4524 9.25337 14.9096 9.25337 16.7005V22.3444H4.05693V12.0051C4.05693 11.6463 3.76606 11.3555 3.40737 11.3555C3.04868 11.3555 2.75781 11.6463 2.75781 12.0051V22.994C2.75781 23.3527 3.04868 23.6436 3.40737 23.6436H9.90293C10.2445 23.6436 10.5241 23.3796 10.55 23.0446C10.5515 23.0294 10.5525 23.0128 10.5525 22.994V16.7005C10.5525 15.6259 11.4268 14.7516 12.5014 14.7516C13.5761 14.7516 14.4503 15.6259 14.4503 16.7005V22.994C14.4503 23.0127 14.4513 23.029 14.4529 23.044C14.4785 23.3793 14.7581 23.6436 15.0999 23.6436H21.5955C21.9542 23.6436 22.245 23.3527 22.245 22.994V12.0051C22.245 11.6463 21.9542 11.3555 21.5954 11.3555Z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>

<style scoped></style>

import type { Employee, EmployeeCategory } from '../models/Employee'
import type { ResourceCollection } from '../types/responses'
import ApiService from './base/ApiService'

export default class EmployeeService extends ApiService {
  async getWithEmployees(): Promise<ResourceCollection<EmployeeCategory>> {
    const endpoint = '/api/employees'
    const response =
            await this.fetch<ResourceCollection<EmployeeCategory>>(endpoint)

    return response
  }

  async getEmployeesByCategory(
    id: number
  ): Promise<ResourceCollection<Employee>> {
    const endpoint = `/api/employees/category/${id}`
    const response =
            await this.fetch<ResourceCollection<Employee>>(endpoint)

    return response
  }
}
